@import "components/global.scss";
$bp: $scale17;

.onboarding {
  position: relative;
  width: 90%;
  box-shadow: 1em 1em 1em rgba(0, 0, 0, 0.15);

  @media (min-width: $bp) {
    width: $scale17;
    margin-top: $scale5;
    min-height: $scale15;
  }
}

.sidebar {
  color: white;
  padding: $scale2;
  border-top-left-radius: $radius;
  background-color: darken($c-purple, 10%);

  > a:first-child {
    margin: 0;
  }

  @media (max-width: $bp) {
    border-top-right-radius: $radius;
  }

  @media (min-width: $bp) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-bottom-left-radius: $radius;
    width: $scale11 + $scale;
  }
}

.main {
  padding: $scale2 $scale2 $scale6 $scale2;

  header {
    width: 100%;
    background-color: transparent;
    border-bottom: 1px dotted $c-border;

    > h2 {
      text-transform: capitalize;
      margin: 0 0 $scale-4 0;
    }

    > span {
      font-size: 0.9em;
      color: lighten($c-text, 12%);
    }
  }

  img {
    max-height: $scale12;
    margin: 0 auto;
  }

  @media (min-width: $bp) {
    margin-left: $scale11 + $scale;

    > p {
      width: 45%;
    }

    img {
      position: absolute;
      width: $scale12;
      top: $scale5;
      right: $scale1;
      z-index: 1;
    }
  }
}

.checklist {
  @media (max-width: $bp) {
    margin-top: $scale1;
  }

  @media (min-width: $bp) {
    position: absolute;
    top: 50%;
    left: $scale2;
    transform: translateY(-50%);
  }
}

.nav {
  position: absolute;
  bottom: $scale;
  left: $scale2;
  right: $scale2;

  @media (min-width: $bp) {
    left: $scale12;
  }
}

.prev {
  float: left;
}

.next {
  float: right;

  span {
    right: 0;
    margin-right: $scale2;
  }
}

.skip {
  position: absolute;
  top: -$scale4;
  right: 0;
  color: rgba(255, 255, 255, 0.8);

  span {
    right: $scale3;
    color: white;
    min-width: $scale7;
    text-align: right;
  }
}
