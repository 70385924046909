@import "components/global.scss";

.header {
  position: relative;
  width: auto;
  z-index: 12;
  height: 60px;
  background: white;
  padding: 18px 40px;
  margin: $scale2 * -1 -40px 40px -40px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: #4a4f56;
  }

  @media (max-width: $mobile) {
    z-index: 1;
    color: $c-text;
    text-align: center;
    background: white;
    padding: $scale $scale;
    border: none;

    margin: -24px -22px 20px -22px;
    padding-left: 5rem;
  }
}
