@import "components/global.scss";

.subnav {
  overflow: hidden;
  padding: 40px;

  display: flex;
  flex-direction: column;

  border-right: 2px solid #e8eeff;
  min-width: 200px;

  a {
    color: $c-text;
    display: inlineblock;
    margin-bottom: 2em;
    text-decoration: none;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #94b1fe;

    &.active {
      font-weight: 600;
      color: #1050fe;
    }
  }
}
