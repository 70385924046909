@import "components/global.scss";

.logo {
  display: block;
  width: $scale9;
  height: $scale3;
  outline: none;
}

.logoBlue {
  display: block;
  width: 136.19px;
  height: 32px;
  outline: none;
}
