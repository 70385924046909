@import "components/global.scss";

.label {
  display: block;
  position: relative;
  color: lighten($c-text, 5%);
  font-size: 0.85em;
  margin-bottom: 6px;
  margin-left: 1em;
  text-transform: capitalize;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 140% */

  /* Black */

  color: #4a4f56;
}

.legend {
  margin-bottom: $scale1;
}

.required:after {
  content: "*";
  color: $c-red;
  margin-left: 0.35em;
  font-size: $scale;
  font-weight: 500;
}
