@import 'components/global.scss';

.social {

  overflow: hidden;
  margin-bottom: $scale2;

  li {

    position: relative;
    float: left;
    width: 3em;
    height: 3em;

  }

  a {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    display: block;
    width: 3em;
    height: 3em;
    text-indent: -9000px;
    cursor: pointer;

  }
}

.facebook {

  background-color: $c-facebook;
  transition: 0.2s all ease-in-out;

  &:hover {

    background-color: darken($c-facebook, 5%);

  }
}

.twitter {

  background-color: $c-twitter;
  transition: 0.2s all ease-in-out;

  &:hover {

    background-color: darken($c-twitter, 5%);

  }
}

.linkedin {

  background-color: $c-linkedin;
  transition: 0.2s all ease-in-out;

  &:hover {

    background-color: darken($c-linkedin, 5%);

  }
}

.mail {

  background-color: $c-green;
  transition: 0.2s all ease-in-out;

  &:hover {

    background-color: darken($c-green, 5%);

  }
}

.icon {

  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}
