@import "components/global.scss";

.auth {
  span {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: $scale1;
  }

  height: 100vh;
  background: linear-gradient(135deg, #fafbff -0.56%, #e6ecff 99.44%);
  background: white;
}
