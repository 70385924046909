@import "components/global.scss";

.btn {
  display: inline-block;
  cursor: pointer;
  color: white;
  font-size: $scale;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  padding: $scale $scale4;
  background-color: $c-green;
  background: linear-gradient(135deg, #6a92ff -0.56%, #2e66fe 99.44%);
  border-radius: 8px;
  padding: 12px 18px;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-color: darken($c-green, 6%);
    transition: all 0.4s ease-in-out;
  }
}

.red {
  background-color: $c-red;

  &:hover {
    background-color: darken($c-red, 5%);
  }
}

.blue {
  background-color: $c-blue;

  &:hover {
    background-color: darken($c-blue, 5%);
  }
}

.small {
  font-size: 0.9em;
  padding: 0.8em $scale2;
}

.big {
  @media (min-width: $mobile) {
    font-size: $scale1;
  }
}

.loader {
  position: relative;
  display: inline-block;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;

  &:after {
    display: none;
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    right: 1em;
    top: 1.05em;
    z-index: 3;
    background-size: contain;
    background-image: url("./icons/ico-loader.svg");
  }

  input,
  button {
    display: block;
    width: 100% !important;
    cursor: pointer;
  }
}

.loading {
  &:after {
    // loading spinner
    display: block;
    transform-origin: center center;
    -webkit-animation: rotate 1s infinite linear;
    -moz-animation: rotate 1s infinite linear;
    -o-animation: rotate 1s infinite linear;
    animation: rotate 1s infinite linear;
  }
}

.outline {
  color: $c-text;
  background: transparent;
  border: 1px solid $c-border-dark;

  &:hover {
    color: white;
    border-color: $c-red;
    background: $c-red;
  }
}

.text {
  cursor: pointer;
  color: $c-text;
}

.icon {
  display: inline-block;
  padding: 0;
  width: $scale1;
  height: $scale1;

  svg {
    width: 100%;
    height: 100%;
  }
}

.iconLabel {
  position: absolute;
  top: 50%;
  margin-left: $scale/2;
  font-size: $scale;
  color: $c-text;
  transform: translateY(-50%);
}

.fullWidth {
  width: 100%;
}
