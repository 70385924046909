@import "components/global.scss";

.badge {
  display: inline-block;
  margin: 0 0.25em;
  padding: 0.25em 0.65em;
  color: white;
  background-color: $c-purple;
  font-size: 0.8em;
  text-align: center;
  text-transform: uppercase;
  border-radius: $radius;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  border-radius: 4px;
}

.blue {
  background: #e8eeff;
  color: #013bda;
}

.red {
  background-color: $c-red;
}

.green {
  background-color: $c-green;
}

.orange {
  background-color: $c-orange;
}
