@import 'components/global.scss';

.footer {

  background-color: white;
  border-top: 1px solid $c-border;

  span, address {

    display: block;
    clear: both;
    opacity: 0.7;
    text-align: left;
    font-style: normal;
    font-size: 0.8em;

  }

  span {

    margin-bottom: $scale1;

  }

  nav {

    float: left;
    clear: both;
    margin-bottom: $scale;

    a {

      color: $c-dark;
      font-weight: 500;
      font-size: 0.9em;
      opacity: 0.7;
      text-decoration: none;
      margin-right: $scale1;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;

      &:hover {

        opacity: 1;
        color: $c-primary;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

      }
    }
  }
}
