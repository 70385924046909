@import "components/global.scss";

.stat {
  position: relative;
  text-align: left;
  // min-height: $scale4;

  // padding: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0px;
  @media only screen and (min-width: 1500px) {
    padding: 20px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.icon {
  // position: absolute;
  // top: 50%;
  // left: $scale2;
  // transform: translateY(-50%);
}

.value {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;

  color: #4a4f56;

  margin-right: 10px;
}

.change {
  font-size: 0.9em;
  text-align: left;
  padding-left: 4px;
}

.up {
  color: #1ec26a;
}
.down {
  color: red;
}

.label {
  font-size: 0.85em;
  color: lighten($c-text, 10%);

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #74778b;

  text-transform: capitalize;
  margin-bottom: 1em;
}

.wrapper {
  width: 45px;
  height: 45px;
  background: linear-gradient(135deg, #6a92ff -0.56%, #2e66fe 99.44%);
  box-shadow: 0px 16px 24px rgba(46, 102, 254, 0.2),
    0px 2px 6px rgba(46, 102, 254, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);

  color: white;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;

  svg {
    stroke: white;
  }
}
