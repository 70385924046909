@import "components/global.scss";

.form {
  width: 100%;
  min-width: 100%;

  > header {
    padding: 0 0 1em 0 !important;

    h2 {
      margin-bottom: $scale/3;
    }

    p {
      color: lighten($c-text, 16%);
      font-size: $scale-1;
    }
  }

  > a {
    display: block;
    margin-bottom: $scale;
  }
}

.loading {
  opacity: 0.5;
}
