@import "components/global.scss";

nav.sidebar {
  @media (min-width: $mobile) {
    position: fixed;
    width: $scale7;
    height: 100%;
    z-index: 9;
    padding: 2rem;
    background-color: $c-dark;
    background: #1a202c;
    transition: all 0.2s ease-out;
    display: flex;
    align-items: center;
    flex-direction: column;

    &.expanded {
      width: $scale11;
      width: 210px;

      a span.label {
        opacity: 1;
        transition: all 0.8s ease-in-out;
      }
    }

    > a {
      width: 100%;
      height: 2em;
      padding: 20px;
    }

    .nav-links {
      width: 100%;
      margin-top: 40px;

      a {
        position: relative;
        display: block;
        text-decoration: none;
        color: white;
        padding: 0;
        width: 100% !important;
        height: $scale5;
        padding: $scale $scale/2;
        font-size: 1em;
        line-height: 1;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 8px 12px;
        margin-bottom: 1em;

        .icon {
          // position: absolute;
          // top: 50%;
          // left: $scale-1;
          // transform: translateY(-50%);
        }

        .label {
          opacity: 0;
          pointer-events: none;
          margin-left: $scale4;

          margin-left: 1em;
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
        }

        &.active {
          font-weight: 600;

          color: white;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.1);
        }

        &:hover {
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }
}

nav.mobile-fixed {
  // moves navigation to bottom
  // of viewport like iOS app
  @media (max-width: $mobile) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    height: auto;
    padding: 0;
    background-color: $c-dark;
    border-bottom: none;

    > a {
      display: none;
    }

    .nav-links {
      position: relative;
      top: auto;
    }

    a {
      position: relative;
      float: left;
      margin: 0;
      height: auto;
      line-height: 1;
      border: none;
      color: white;
      padding: $scale1 0;
      font-size: 0.9em;
      text-align: center;

      .ico {
        position: relative;
        top: -0.2em;
      }

      .label {
        position: absolute;
        bottom: 0.65em;
        left: 50%;
        font-size: 0.8em;
        transform: translateX(-50%);
      }

      &.active {
        background-color: lighten($c-dark, 2%);
      }
    }
  }

  .btn-togglenav {
    display: none;
  }

  a {
    text-decoration: none;
  }
}

nav.mobile-popup {
  @media (min-width: $mobile) {
    .btn-togglenav {
      display: none !important;
    }
  }

  @media (max-width: $mobile) {
    position: fixed;
    top: $scale1;
    left: $scale1;
    width: $scale2;
    height: $scale2;
    z-index: 10;
    background-color: transparent;

    > a {
      display: none;
    }

    .btn-togglenav {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
    }

    &.open {
      .nav-links {
        display: block !important;
      }
    }

    section.nav-links {
      position: fixed;
      display: none !important;
      padding: $scale;
      z-index: 9;
      top: $scale-2;
      left: $scale/2;
      right: $scale/2;
      padding-top: $scale5;
      background-color: white;
      border-radius: $radius;
      box-shadow: 0 0 5em rgba(0, 0, 0, 0.25);
      transition: all 0.2s ease-in-out;

      a {
        position: relative;
        display: block;
        float: none;
        color: $c-text;
        padding: 0;
        width: 100% !important;
        line-height: 1;
        margin-bottom: $scale;
        font-size: 1.15em;
        text-decoration: none;

        .ico {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        .label {
          margin-left: $scale-2;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.active {
          color: $c-text;
          background: none;
        }
      }
    }
  }
}
