@import "components/global.scss";

.card {
  position: relative;
  padding: $scale2;
  padding: 20px;
  z-index: 1;
  margin-bottom: 0;
  background: white;
  border-radius: $radius;

  &:last-of-type {
    margin-bottom: 0;
  }

  header {
    position: relative;
    overflow: hidden;
    text-align: left;
    color: $c-text;
    padding-bottom: $scale1;
    margin-bottom: $scale3;
    background-color: white;
    // border-bottom: 1px dotted $c-border;

    h1 {
      float: left;
      margin: 0;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      /* Black */

      color: #4a4f56;
    }

    a {
      float: right;
      margin-top: 0.2em;
      font-size: 0.9em;
    }
  }

  table {
    min-height: $scale5;
    margin-top: -$scale1;
    margin-bottom: -$scale2;

    @media (max-width: $mobile) {
      margin-top: 0;
    }
  }
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.shadow {
  box-shadow: 0em 0.15em 0.4em rgba(0, 0, 0, 0.05);
}

.loading {
  min-height: $scale13;
}

.restrictWidth {
  max-width: $scale15;
}

.fullWidth {
  width: 100%;
  min-width: 100%;
}

.noPadding {
  padding: 0;
}

.p40 {
  padding: 40px !important;
}

.lgTitle {
  header {
    padding-bottom: 0;

    h1 {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;

      color: #4a4f56;
    }
  }
}
