@import "components/global.scss";

.hoverNav {
  // position: absolute;
  // top: 50%;
  cursor: pointer;
  // width: $scale11;
  min-width: $scale10;
  color: $c-text-light;
  z-index: 11;
  // transform: translateY(-50%);

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 15px;
  }

  nav {
    position: absolute;
    top: 100%;
    width: 100%;
    padding: $scale/2;
    margin-top: 12px;
    text-align: left;
    border-radius: $radius;
    box-shadow: 0px 0px 0.5em rgba(0, 0, 0, 0.15);
    background: rgba(255, 255, 255, 0.8);

    a,
    button {
      display: block;
      width: 100%;
      color: $c-text;
      font-weight: 400;
      opacity: 0.85;
      font-size: $scale-1;
      border-radius: 0;
      border-bottom: 1px solid $c-border;
      background-color: transparent;

      &:hover {
        opacity: 1;
        background-color: transparent;
      }

      &:last-child {
        border-bottom: 0 !important;
      }
    }

    &:after {
      content: " ";
      position: absolute;
      bottom: 100%;
      left: 1em;
      pointer-events: none;
      border: solid transparent;
      border-bottom-color: white;
      border-width: 6px;
    }
  }
}

.left {
  left: 0;
  text-align: left;

  nav:after {
    right: auto;
    left: 0.5em;
  }
}

.right {
  right: $scale2;
  text-align: right;

  nav:after {
    right: 0.5em;
    left: auto;
  }
}

.dark {
  nav {
    background: $c-dark;
    background: rgba(0, 0, 0, 0.8);

    a,
    button {
      color: lighten($c-text-light, 12%);
      border-bottom: 1px solid lighten($c-dark, 10%);
    }

    &:after {
      border-bottom-color: $c-dark;
    }
  }
}

.icon {
  position: relative;
  top: 0.1em;
  margin-right: $scale/2;
}

.avatar {
  display: inline;
  // position: relative;
  // top: 0.1em;
  margin-right: 1em;

  border-radius: 100px;
  object-fit: cover;
  width: 42px;
  height: 42px;

  border: 4px solid #f1f1f1;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuText {
  margin-right: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  color: #4a4f56;
}
